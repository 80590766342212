.heading {
    margin-bottom: calc(2 * var(--gap));
    padding: calc(2 * var(--gap));
    background-color: hsl(var(--color-gold));
    color: hsl(var(--color-white));
    font-size: var(--font-size-3);
    font-weight: 900;
    text-align: center;

    @media (min-width: 600px) {
        font-size: var(--font-size-4);
    }

    @media (min-width: 900px) {
        font-size: var(--font-size-5);
    }
}

.form {
    width: var(--global-container-width);
    max-width: calc(0.5 * var(--global-container-max-width));
    margin: 0 auto calc(4 * var(--gap));
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
}

.submitting {

    :global .row {
        opacity: 0.15;
        transition: opacity var(--transition-in) var(--transition-timing);
    }
}

.loader {
    z-index: var(--zindex-loader);
}

.spacing {
    margin-bottom: calc(2 * var(--gap)) !important;
}

.agreement {
    align-items: center;
}

.hidden {
    display: none;
}

.button {
    margin: 0 auto;
    display: flex;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.paragraph {
    font-size: var(--font-size-3);
    text-align: center;

    @media (min-width: 600px) {
        font-size: var(--font-size-4);
    }

    @media (min-width: 900px) {
        font-size: var(--font-size-5);
    }
}
